import {useContext, useState} from "react";

// Router
import {useNavigate} from "react-router-dom";

// Images
import closeModal from "../assets/images/icons/close-modal.svg";

// Components
import Button from "./Button";

// Context
import {MainContext} from "../utils/MainContext";

// Translation
import {useTranslation} from "react-i18next";

import * as AdditionalsService from "../Modules/Additionals/Service/AdditionalsService";
import Loading from "./Loading";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";
import {useUserLogOut} from "../Modules/Auth/Service/AuthService";
import {paymentInfoTypes} from "../constants/constants";

const BuyPackModal = ({ userId }) => {
  // Translation
  const { t } = useTranslation();

  const userLogOut = useUserLogOut();

  // Router
  const navigate = useNavigate();
  // Global states
  const {
    selectedPack,
    openModal,
    setOpenModal,
    setRegisterSuccessMsg,
    setDashboardPage,
  } = useContext(MainContext);

  // Local states
  const [billYear, setBillYear] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setOpenModal(false);
    setBillYear(false);
  };

  const subscribePlan = async () => {
    setLoading(true);
    const body = {
      plan: selectedPack.id,
      type: billYear ? "yearly" : "monthly",
      success_url: `https://bucard.az/payment-info/${paymentInfoTypes.success}`,
      cancel_url: `https://bucard.az/payment-info/${paymentInfoTypes.cancel}`,
      decline_url: `https://bucard.az/payment-info/${paymentInfoTypes.decline}`,
    };
    try {
      if (selectedPack.profile_type !== PROFILE_TYPES.free) {
        const res = await AdditionalsService.subscribePlan(userId, body);
        if (res.payment_url) {
          setOpenModal(false);
          userLogOut();
          window.location.href = res.payment_url;
        }
      } else {
        setRegisterSuccessMsg(t("buyPackModal.createdMessage"));
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDashboardPage("boxes");
      setOpenModal(false);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={openModal ? "checkoutModal open" : "checkoutModal"}>
      <div className="modalOverlay" onClick={onClose}></div>
      <div className="modalBody">
        <div className="modalBg"></div>
        <div className="modalContent">
          <div className="closeModal" onClick={onClose}>
            <img src={closeModal} alt="close-modal" />
          </div>
          <h2 className="modalTitle">{t("buyPackModal.title")}</h2>
          <div className="orderPack">
            <div className="changeBill">
              <div
                className={billYear ? "billBtn" : "billBtn activeBtn"}
                onClick={() => setBillYear(false)}
              >
                <p className="btnHead">{t("buyPackModal.billMonth")}</p>
                <p className="btnContent">
                  <span>{selectedPack.monthly_price}</span> AZN /{" "}
                  {t("buyPackModal.monthly")}
                </p>
              </div>
              <div
                className={billYear ? "billBtn activeBtn" : "billBtn"}
                onClick={() => setBillYear(true)}
              >
                <p className="btnHead">
                  {t("buyPackModal.billYear")}
                  {selectedPack?.discount && (
                    <span className="discount">-{selectedPack?.discount}%</span>
                  )}
                </p>
                <p className="btnContent">
                  <span>{selectedPack.yearly_price}</span> AZN /{" "}
                  {t("buyPackModal.yearly")}
                </p>
              </div>
            </div>
            <div className="order">
              <ul className="priceList">
                <li className="priceItem">
                  {t("buyPackModal.subtotal")}{" "}
                  <span>
                    {billYear
                      ? parseFloat(selectedPack.priceNoDiscount).toFixed(2)
                      : selectedPack.monthly_price}{" "}
                    AZN
                  </span>
                </li>
                {billYear && (
                  <li className="priceItem">
                    {t("buyPackModal.discount")}
                    <span>
                      {parseFloat(
                        selectedPack.priceNoDiscount - selectedPack.yearly_price
                      ).toFixed(2)}{" "}
                      AZN
                    </span>
                  </li>
                )}
                <li className="priceItem">
                  {t("buyPackModal.finalPrice")}{" "}
                  <span>
                    {billYear
                      ? selectedPack.yearly_price
                      : selectedPack.monthly_price}{" "}
                    AZN
                  </span>
                </li>
              </ul>
              <p className="billInfo">
                {t("buyPackModal.info1")}{" "}
                {billYear
                  ? selectedPack.yearly_price
                  : selectedPack.monthly_price}{" "}
                AZN {t("buyPackModal.info2")}{" "}
                {billYear
                  ? t("buyPackModal.yearly")
                  : t("buyPackModal.monthly")}{" "}
                {billYear
                  ? selectedPack.yearly_price
                  : selectedPack.monthly_price}{" "}
                AZN {t("buyPackModal.info3")}
              </p>
              <Button className="orderBtn" onClick={subscribePlan}>
                {t("buyPackModal.btnValue")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyPackModal;
