import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import { MainContext } from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";
import CustomInput from "../../../components/CustomInput";
import linkedinIcon from "../../../assets/images/icons/linkedin-in.svg";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { toastMsg } from "../../../utils/swal";

const StepOne = ({ setIsActivated }) => {
  const navigate = useNavigate();
  // Local states
  const [emailErrorVisible, setEmailErrorVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setStep, setEmail, email, setPhone } = useContext(MainContext);

  const phoneSchema = object({
    email: string().required(t("registerStepOne.errorMsg")),
  });

  const [errorMsg, setErrorMsg] = useState("");
  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(phoneSchema),
  });

  const handleChange = (value) => {
    setEmail(value);
    setPhone(value);
    setValue("email", value, { shouldValidate: true });
  };

  const onSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const body = {
      email: email,
    };

    try {
      await AuthService.userRegister(body);
      setEmailErrorVisible(false);
      setStep(2);
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || t("errors.systemError");
      setErrorMsg(errorMessage);
      if (err?.response?.data?.is_activated === "False") {
        setIsActivated(true);
        setStep(2);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitGoogle = async (id) => {
    setLoading(true);
    try {
      const res = await AuthService.userGoogleRegister({
        id_token: id,
      });
      if (res?.access) {
        setStep(3);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitLinkedin = async (e) => {
    e.preventDefault();
    const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${window.location.origin}/auth/linkedin&scope=openid%20profile%20email`;
    try {
      window.location.href = linkedInAuthURL;
    } catch (error) {
      console.log("Popup error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {isSubmitting && <Loading />}
      <h2 className="formTitle">{t("registerStepOne.formTitle")}</h2>
      <div className="socialBtnGroup">
        <GoogleLogin
          onSuccess={(res) => {
            console.log(res);
            onSubmitGoogle(res?.credential);
          }}
          onError={(err) => console.log("error", err)}
        />
        {/*<button className="btnWithLogo google" onClick={onSubmitGoogle}>*/}
        {/*  <div className="icon">*/}
        {/*    <img src={googleIcon} alt="google-icon" />*/}
        {/*  </div>*/}
        {/*  <span className="text">Google</span>*/}
        {/*</button>*/}
        <button className="btnWithLogo linkedin" onClick={onSubmitLinkedin}>
          <div className="icon">
            <img src={linkedinIcon} alt="linked-in-icon" />
          </div>
          <span className="text">LinkedIn</span>
        </button>
      </div>
      <div className="inputBox">
        {/* <label htmlFor="phone">{t("registerStepOne.phone")} *</label> */}
        {/* <PhoneInput
          international
          defaultCountry="AZ"
          value={phone || ""}
          onChange={handleChange}
        /> */}
        <CustomInput
          type={"email"}
          name={"email"}
          onChange={handleChange}
          label={t("registerStepOne.email")}
          placeholder={t("registerStepOne.emailPlace")}
        />
        {(errors.email || errorMsg) && emailErrorVisible && (
          <span className="errorAlert">
            {errors?.email?.message || errorMsg}
          </span>
        )}
      </div>
      <Button
        className="formBtn"
        type="submit"
        onClick={() => setEmailErrorVisible(true)}
      >
        {t("registerStepOne.btnValue")}
      </Button>
    </form>
  );
};

export default StepOne;
