import React, {useState} from "react";
import * as ProfileService from "../../Service/ProfileService";
import CustomInput from "../../../../components/CustomInput";
import Spinner from "../../../../components/Spinner";
import Swal from "sweetalert2";
import {toastMsg} from "../../../../utils/swal";
import {useTranslation} from "react-i18next";

const ProfileEmailEdit = ({ profileData, closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_email: profileData?.extra_emails || [],
  });
  const [newEmail, setNewEmail] = useState({
    email: "",
    isVerified: false,
  });

  const onSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      let res = null;
      res = await ProfileService.profileEdit({
        extra_email: [{ email: newEmail.email }],
      });
      setProfileData((prev) => ({
        ...prev,
        extra_emails: res?.extra_emails,
      }));
      Swal.fire(
        t("profileEmailEdit.success"),
        t("profileEmailEdit.notify"),
        "success"
      );
      closeModal();
    } catch (error) {
      if (error?.response?.data?.extra_email?.includes("exists")) {
        toastMsg(t("profileEmailEdit.alreadyExsist"), "#ffa700", "#000000");
        setNewEmail({
          email: "",
          isVerified: false,
        });
      }
      if (error?.response?.data?.extra_email[0]?.email?.length) {
        setErrorMessage(t("profileEmailEdit.invalidEmailFormat"));
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeEmail = async (email) => {
    Swal.fire({
      title: email?.email,
      text: t("profileEmailEdit.confirmDelete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("profileEmailEdit.yes"),
      cancelButtonText: t("profileEmailEdit.no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "extra_email",
            id: email.id,
          });
          setProfileData((prev) => ({
            ...prev,
            extra_emails: res.data.extra_emails,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            extra_email: res.data.extra_emails,
          }));
          Swal.fire(
            t("profileEmailEdit.success"),
            t("profileEmailEdit.emailDeleted"),
            "success"
          );
          closeModal();
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire(
          t("profileEmailEdit.canceled"),
          t("profileEmailEdit.emailNotRemoved"),
          "error"
        );
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileEmailEdit">
      <div className="editEmailArea">
        {profileInfo.extra_email.map((email) => (
          <button
            key={email.id}
            className="phoneCaption"
            onClick={() => removeEmail(email)}
          >
            {email.email}
            <span>x</span>
          </button>
        ))}
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="infoEmailForm">
        <CustomInput
          label={"Yeni elektron poçt"}
          placeholder="info@bucard.az"
          errorMessage={errorMessage}
          value={newEmail.email}
          onChange={(value) => {
            const email = value.trim();
            setNewEmail({ email });
            if (email === "") {
              setErrorMessage(t("profileEmailEdit.writeEmail"));
            } else {
              setErrorMessage("");
            }
          }}
        />
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("profileEmailEdit.cancel")}
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          disabled={errorMessage !== ""}
        >
          {t("profileEmailEdit.save")}
        </button>
      </div>
    </div>
  );
};

export default ProfileEmailEdit;
