import profilImgFree from "../assets/images/profil-img-test.svg";
import profilDescription from "../assets/images/prf-description-test.svg";
import dots from "../assets/images/icons/dots.svg";
import bgDetail from "../assets/images/upgrade-pack-bg-dtail.png";
import {useTranslation} from "react-i18next";

const UpgradePackPacketArea = () => {
  const { t } = useTranslation();

  return (
    <div className="upgradePacketArea">
      <div className="packetCard">
        <div className="profilArea">
          <div className="profil">
            <div className="top">
              <div className="profilImg">
                <img src={profilImgFree} alt="profil-img" />
              </div>
              <div className="destriptionImg">
                <img src={profilDescription} alt="destription-img" />
              </div>
            </div>
            <div className="bottom">
              <div className="about">
                <h3 className="profilName">Elon Musk</h3>
              </div>
              <h6 className="tel">+994 12 3456789</h6>
              <p className="addition">
                {" "}
                1286 {t("upgradePackPacketArea.save")}
              </p>
            </div>
            <img src={dots} alt="dots" className="threeDot" />
          </div>
          <h4 className="profilTypeName">
            {t("upgradePackPacketArea.personalProfil")}
          </h4>
        </div>
        <div className="overlayBg">
          <img src={bgDetail} alt="description" />
        </div>
      </div>
    </div>
  );
};

export default UpgradePackPacketArea;
