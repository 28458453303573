import {useContext, useState} from "react";

// Router
import {useLocation, useNavigate} from "react-router-dom";

// Components
import Button from "./Button";

// Context
import {LoginContext} from "../utils/Auth";
import {MainContext} from "../utils/MainContext";

// Translation
import {useTranslation} from "react-i18next";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";

const PacketCard = ({ id, data, state, profile_type }) => {
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  // Translation
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  // Detect page url
  const navigate = useNavigate();
  const pageUrl = useLocation();

  // Global states
  const { buyPacket, setRegisterSuccessMsg, setDashboardPage } =
    useContext(MainContext);
  const { isUserIn, user } = useContext(LoginContext);

  const printContentByLang = (item) => {
    switch (lng) {
      case "az":
        return item.title_az;
      case "en":
        return item.title_en;
      case "ru":
        return item.title_ru;
      default:
        return item.title_az;
    }
  };

  const calculateDiscount = (yearlyPrice, monthlyPrice) => {
    const monthsInYear = 12;
    const totalMonthlyPrice = monthsInYear * monthlyPrice;
    const discountAmount = totalMonthlyPrice - yearlyPrice;
    const discountPercentage = (discountAmount / totalMonthlyPrice) * 100;
    return parseInt(discountPercentage);
  };

  const checkButtonClick = (planType) => {
    if (pageUrl.pathname.includes("/select-pack")) {
      if (planType === PROFILE_TYPES.free) {
        setRegisterSuccessMsg(t("packetCard.createdMessage"));
        navigate("/login");
      } else {
        if (profile_type !== data?.profile_type) {
          buyPacket({
            ...data,
            discount: calculateDiscount(data.yearly_price, data.monthly_price),
            priceNoDiscount: parseFloat(data.monthly_price * 12),
          });
        } else {
          return;
        }
      }
    } else if (pageUrl.pathname === "/") {
      sessionStorage.setItem("trial", JSON.stringify(true));
      setRegisterSuccessMsg(t("packetCard.infoMsg"));
      navigate("/register");
    } else if (pageUrl.pathname === "/dashboard") {
      sessionStorage.setItem("trial", JSON.stringify(true));
      setDashboardPage("boxes");
    } else {
      buyPacket({
        ...data,
        discount: calculateDiscount(data.yearly_price, data.monthly_price),
        priceNoDiscount: parseFloat(data.monthly_price * 12),
      });
    }
  };

  return (
    <div className="packetCard">
      <h4 className="cardTitle">{printContentByLang(data)}</h4>
      {isUserIn && data?.profile_type === user?.profile?.profile_type && (
        <span>{t("packetCard.subscribed")}</span>
      )}
      <p className="cardPrice">
        {state ? data.yearly_price : data.monthly_price} AZN
        <span className="perMonth">
          {state ? t("packetCard.everyYear") : t("packetCard.everyMonth")}
        </span>
      </p>
      {data.profile_type !== PROFILE_TYPES.free ? (
        state && (
          <p className="packetDiscount">
            {t("packetCard.yearly")}
            <span className="annually">
              {`${calculateDiscount(
                data.yearly_price,
                data.monthly_price
              )}% ${t("packetCard.discount")}`}
            </span>
          </p>
        )
      ) : (
        <p className="packetDiscount">{t("packetCard.freePlan")}</p>
      )}
      <span className="division"></span>
      <ul className="featureList">
        {data?.features
          ?.slice(0, showAllFeatures ? data.features.length : 4)
          .map((item, index) => {
            return (
              <li className="listItem" key={index}>
                {printContentByLang(item)}
              </li>
            );
          })}
        <li
          className={`more ${
            data.profile_type === PROFILE_TYPES.free && "whiteText"
          }`}
          onClick={() => setShowAllFeatures(!showAllFeatures)}
        >
          {showAllFeatures ? t("packetCard.less") : t("packetCard.more")}
        </li>
      </ul>
      {data?.profile_type === PROFILE_TYPES.personal && (
        <p
          className="packetDiscount"
          style={{ textAlign: "center", marginBottom: 8 }}
        >
          {t("freeTrialCard.description")}
        </p>
      )}
      <Button
        className="cardBtn"
        onClick={() => checkButtonClick(data.profile_type)}
        disabled={data.profile_type === PROFILE_TYPES.business}
      >
        {data?.profile_type === PROFILE_TYPES.free
          ? t("packetCard.free")
          : profile_type === data?.profile_type
          ? t("packetCard.selectedPlan")
          : data.profile_type === PROFILE_TYPES.business
          ? t("packetCard.noActive")
          : t("freeTrialCard.buttonTitle")}
      </Button>
    </div>
  );
};

export default PacketCard;

/*
{
    "message": "A free trial is already active."
}
https://api.bucard.az/api/v1/start-free-trial/
https://api.stg.bucard.az/api/v1/profile/verify-extra-email/57a5c77f-f347-4440-b16d-1d64adbb6d47
https://api.stg.bucard.az/api/v1/profile/verify-extra-email/88ccb55c-9c89-43ce-80c2-371c90af7754
*/
