import {useContext} from "react";

// Router
import {Link} from "react-router-dom";

// Images
import boxUsers from "../assets/images/icons/box-users.svg";
import menuDots from "../assets/images/icons/menu-dots.svg";
import mobileBoxIcon from "../assets/images/icons/mobile-box.svg";
import deleteIcon from "../assets/images/icons/deleteIcon.svg";

// Context
import {MainContext} from "../utils/MainContext";

// Swal
import {confirmSwal} from "../utils/swal";

// Translation
import {useTranslation} from "react-i18next";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const MobileBox = ({ data, openBoxMenu, openBoxId, setOpenBoxId }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setEditBoxOpen, setSelectedBox, setEditBoxId, boxes, setBoxes } =
    useContext(MainContext);

  // Delete selected box
  const deleteBox = (boxId) => {
    confirmSwal(
      t("allBoxes.deleteQuestion"),
      t("allBoxes.deleteInfo"),
      t("allBoxes.grant"),
      t("allBoxes.deny"),
      setOpenBoxId,
      async () => {
        setOpenBoxId(null);
        try {
          await DashboardService.deleteBox(boxId);
          const updated = boxes.filter((item) => item.id !== boxId);
          setBoxes(updated);
        } catch (err) {
          console.log(err);
        }
      },
      t("confirmModal.grant"),
      t("confirmModal.deny")
    );
  };

  return (
    <Link
      className="mobileBox"
      to={`/box-details/${data.id}`}
      onClick={(e) => {
        setSelectedBox(data);
      }}
    >
      <div className="boxLeft">
        <div className="boxIcon">
          <p className="emoji">{data.icon}</p>
        </div>
        <div className="boxInfo">
          <p className="boxName">{data.title}</p>
          <div className="boxCount">
            <div className="boxUsers">
              <img src={boxUsers} alt="box-users-icon" />
            </div>
            <span className="usersCount">{data.profiles_count}</span>
          </div>
        </div>
      </div>
      <div className="boxRight">
        <div
          className="boxMenu"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openBoxMenu(data.id);
          }}
        >
          {data.title !== "Personals" && data.title !== "Companies" && (
            <img src={menuDots} alt="menu-dots" />
          )}
        </div>
      </div>
      <div
        className={openBoxId === data.id ? "boxOptions isOpen" : "boxOptions"}
        id={data.id}
      >
        <div className="seperator"></div>
        <div className="boxDetail">
          <p>{openBoxId === data.id && data.title}</p>
          <div className="boxIcon">
            <p className="emoji">{openBoxId === data.id && data.emoji}</p>
          </div>
        </div>
        <div
          className="option"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setEditBoxOpen(true);
            setEditBoxId(data.id);
            setOpenBoxId(null);
          }}
        >
          <div className="icon">
            <img
              src={mobileBoxIcon}
              alt="mobile-box-icon"
              style={{
                width: 24,
                height: 24,
              }}
            />
          </div>
          <p className="optionInfo">{t("allBoxes.edit")}</p>
        </div>
        <div
          className="option"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            deleteBox(data.id);
          }}
        >
          <div className="icon">
            <img
              src={deleteIcon}
              alt="options-icon"
              style={{
                width: 20,
                height: 22,
              }}
            />
          </div>
          <p className="optionInfo">{t("allBoxes.delete")}</p>
        </div>
      </div>
    </Link>
  );
};

export default MobileBox;
