import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import {useTranslation} from "react-i18next";

const ProfileCreateExp = ({ closeModal, data, setProfileData }) => {
  const { t } = useTranslation();
  const [profileInfo, setProfileInfo] = useState(
    data || {
      title: "",
      start_date: "",
      end_date: "",
      company: "",
      description: "",
      employment_type: "",
      still_working_there: false,
    }
  );

  const [errors, setErrors] = useState({
    title: false,
    start_date: false,
    end_date: false,
    company: false,
    description: false,
    employment_type: false,
  });

  const validateStates = (e) => {
    e.preventDefault();

    const newErrors = {
      title: !profileInfo?.title,
      start_date: !profileInfo?.start_date,
      end_date: !profileInfo?.still_working_there && !profileInfo?.end_date,
      company: !profileInfo?.company,
      description: !profileInfo?.description,
      employment_type: !profileInfo?.employment_type,
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error === true);
    if (!hasErrors) {
      onSubmit();
    }
  };

  const handleEmploymentTypeChange = (event) => {
    setProfileInfo((prev) => ({
      ...prev,
      employment_type: event.target.value,
    }));
  };

  const onSubmit = async () => {
    const body = {
      experience: profileInfo.still_working_there
        ? {
            ...(data?.id ? { id: data.id } : {}),
            title: profileInfo.title,
            start_date: profileInfo.start_date,
            company: profileInfo.company,
            description: profileInfo.description,
            employment_type: profileInfo.employment_type,
            still_working_there: profileInfo.still_working_there,
          }
        : profileInfo,
    };
    try {
      const res = await ProfileService.profileEdit(body);
      setProfileData((prev) => ({
        ...prev,
        experiences: res?.experiences,
      }));
      closeModal();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <form className="createProfile">
      <div className="experienceComp">
        <div className="experienceInp">
          <CustomInput
            label={t("profileCreateExp.position")}
            placeholder={t("profileCreateExp.positionPlace")}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                description: value,
              }));
            }}
            value={profileInfo.description}
            errorMessage={
              errors.description ? t("profileCreateExp.noEmpty") : ""
            }
          />
          <CustomInput
            placeholder={t("profileCreateExp.companyName")}
            label={t("profileCreateExp.companyName")}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                company: value,
              }));
            }}
            value={profileInfo.company}
            errorMessage={errors.company ? t("profileCreateExp.noEmpty") : ""}
          />
        </div>
        <div className="experienceInp2">
          <div className="selectInp">
            <label htmlFor="employment-type">
              {t("profileCreateExp.workType")}
            </label>
            <select
              name="employment-type"
              id="employment-type"
              value={profileInfo.employment_type}
              onChange={handleEmploymentTypeChange}
            >
              <option value="" disabled>
                {t("employmentTypes.selectWork")}...
              </option>
              <option value="full_time">
                {t("employmentTypes.full_time")}
              </option>
              <option value="part_time">
                {t("employmentTypes.part_time")}
              </option>
              <option value="internship">
                {t("employmentTypes.internship")}
              </option>
              <option value="contract">{t("employmentTypes.contract")}</option>
              <option value="freelance">
                {t("employmentTypes.freelance")}
              </option>
              <option value="self_employed">
                {t("employmentTypes.self_employed")}
              </option>
              <option value="volunteer">
                {t("employmentTypes.volunteer")}
              </option>
            </select>
          </div>
          <div className="timeBox">
            <CustomInput
              label={t("profileCreateExp.startDate")}
              placeholder={"01.01.1990"}
              type={"date"}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  start_date: value,
                }));
              }}
              value={profileInfo.start_date}
              errorMessage={
                errors.start_date ? t("profileCreateExp.noEmpty") : ""
              }
            />
            <CustomInput
              label={t("profileCreateExp.endDate")}
              placeholder={"01.01.1995"}
              type={"date"}
              disabled={profileInfo.still_working_there}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  end_date: value,
                }));
              }}
              value={profileInfo.end_date}
              errorMessage={
                errors.end_date ? t("profileCreateExp.noEmpty") : ""
              }
            />
          </div>
        </div>
        <div className="stillWorking">
          <input
            type="checkbox"
            checked={profileInfo.still_working_there}
            onChange={(e) =>
              setProfileInfo((prev) => ({
                ...prev,
                still_working_there: e.target.checked,
                end_date: "",
              }))
            }
          />
          <p>{t("profileCreateExp.stillWorkHere")}</p>
        </div>
        <CustomInput
          label={t("profileCreateExp.title")}
          placeholder={t("profileCreateExp.titlePlace")}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              title: value,
            }));
          }}
          value={profileInfo.title}
          errorMessage={errors.title ? t("profileCreateExp.noEmpty") : ""}
        />
      </div>
      <div className="btnGroup">
        <button
          className="cancel btn"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("profileCreateExp.cancel")}
        </button>
        <button className="saveBtn btn" onClick={validateStates}>
          {t("profileCreateExp.save")}
        </button>
      </div>
    </form>
  );
};

export default ProfileCreateExp;
