import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import * as AuthService from "../Service/AuthService";
import { LoginContext } from "../../../utils/Auth";
import dayjs from "dayjs";

const AuthPage = () => {
  const navigate = useNavigate();
  const { authType } = useParams();
  const { setIsUserIn } = useContext(LoginContext);

  useEffect(() => {
    if (authType === "linkedin") {
      const code = window.location.search.replace("?code=", "");
      if (code) {
        submitLinkedinRegister(code);
      }
    }
  }, [authType]);

  const submitLinkedinRegister = async (code) => {
    try {
      const res = await AuthService.userLinkedinRegister({
        code,
        redirect_uri: `${window.location.origin}/auth/linkedin`,
      });
      if (res?.access) {
        localStorage.setItem("X-Authorization", res.access);
        localStorage.setItem("X-Refresh", res.refresh);
        localStorage.setItem("tokenDate", JSON.stringify(dayjs()));
        localStorage.setItem("userIn", JSON.stringify(true));
      }
      setIsUserIn(true);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <main>
      <section className="register">
        <Loading />
      </section>
    </main>
  );
};
export default AuthPage;
