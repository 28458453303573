import {useContext, useState} from "react";

// Router
import {Link, useLocation, useNavigate} from "react-router-dom";

// Images
import logOut from "../assets/images/icons/logout.svg";
import blueCircle from "../assets/images/icons/blue-circle.svg";
import searchIcon from "../assets/images/icons/search-icon.svg";
import logo from "../assets/images/logo.svg";
import linkIcon from "../assets/images/icons/link-icon.svg";
import settings from "../assets/images/icons/setting-2.svg";

// Components
import LanguageSelector from "./LanguageSelector";

// Context
import {LoginContext} from "../utils/Auth";
import {MainContext} from "../utils/MainContext";

// React hook form
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

// yup
import {object, string} from "yup";

// Translation
import {useTranslation} from "react-i18next";

import {useUserLogOut} from "../Modules/Auth/Service/AuthService";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";

import {DEFAULT_IMAGES} from "../constants/constants";
import useWindowDimensions from "../hooks/useWindowDimensions";

const DashboardHeader = () => {
  // Translation
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  // Global states
  const { user, isUserIn } = useContext(LoginContext);
  const { setSharedProfileModal, setDashboardPage, setMySelfProfilView } =
    useContext(MainContext);

  const isPro = user?.profile?.profile_type === PROFILE_TYPES.personal;

  // Router
  const navigate = useNavigate();
  const path = useLocation();

  // Custom hook
  const userLogOut = useUserLogOut();

  // Local states
  const [openMenu, setOpenMenu] = useState(false);

  // Yup
  const searchSchema = object({
    searchedData: string().required().trim(),
  });

  // React hook form
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  const onSubmit = (data) => {
    setMySelfProfilView(false);
    navigate(`/search-result?filter=${data.searchedData}`);
    if (path.pathname.includes("/search-result")) {
      window.location.reload();
    }
  };

  const logOutUser = () => {
    userLogOut();
    setMySelfProfilView(false);
  };

  const handleLogoClick = () => {
    if (path.pathname.includes("/dashboard")) {
      setDashboardPage("boxes");
      setMySelfProfilView(false);
    } else {
      if (isUserIn) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <header
      className={
        path.pathname.includes("shared-user")
          ? "dashboardHeader shared"
          : "dashboardHeader"
      }
      style={
        path.pathname.includes("settings") && width < 768
          ? { display: "flex" }
          : {}
      }
    >
      <div
        className="mobileHeader"
        style={
          path.pathname.includes("settings") && width < 768
            ? { display: "block" }
            : {}
        }
      >
        <Link to={"/"}>bucard.az</Link>
      </div>
      <div
        className={`outsildeClickOverlay ${openMenu && "active"}`}
        onClick={() => setOpenMenu(false)}
      ></div>
      <div className="logo" onClick={handleLogoClick}>
        <Link to="/dashboard">
          <img src={logo} alt="dashboard-logo" />
        </Link>
      </div>
      {(path.pathname.includes("shared-user") && isUserIn) ||
        (isUserIn && (
          <form
            className={`searchBar ${errors.searchedData && "error"}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="text"
              id="searchBar"
              placeholder={t("dashboardHeader.searchPlaceholder")}
              {...register("searchedData")}
            />
            <button type="submit">
              <img src={searchIcon} alt="search-icon" />
            </button>
          </form>
        ))}
      <div className="userArea">
        <LanguageSelector />
        {(path.pathname.includes("shared-user") && isUserIn) ||
          (isUserIn && (
            <div
              className="shareIcon"
              onClick={() => setSharedProfileModal(true)}
            >
              <div className="icon">
                <img src={linkIcon} alt="link-icon" />
              </div>
              <p className="shareInfo">{t("dashboardHeader.share")}</p>
            </div>
          ))}
        {!isUserIn ? (
          <Link className="signIn" to="/login">
            <p>{t("header.login")}</p>
          </Link>
        ) : (
          <div className="account">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <div
                key={user?.profile?.avatar}
                className={`avatar ${isPro && "avatarPro"} ${
                  user?.profile?.avatar === null && "isDefault"
                }`}
              >
                {user?.profile?.avatar ? (
                  <img
                    src={
                      user?.profile?.avatar
                        ? user?.profile?.avatar
                        : user?.profile?.avatar
                    }
                    alt={user?.profile?.full_name}
                  />
                ) : (
                  <img
                    src={DEFAULT_IMAGES.avatar_b64}
                    alt="default-icon"
                    className="defaultIcon"
                  />
                )}
              </div>
              <p className={"title"}>{user?.full_name}</p>
            </div>
            <ul className={openMenu ? "accountMenu open" : "accountMenu"}>
              <li className="accountMenuItem">
                <Link
                  to={`/profile/${user?.username}`}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  <img
                    src={
                      user?.profile?.avatar
                        ? user?.profile?.avatar
                        : DEFAULT_IMAGES.avatar_b64
                    }
                    alt="medal-icon"
                  />
                  {user?.full_name}
                </Link>
              </li>
              {user?.profile?.profile_type === PROFILE_TYPES.free && (
                <li className="accountMenuItem">
                  <Link
                    to="/dashboard"
                    onClick={() => {
                      setMySelfProfilView(false);
                      setDashboardPage("upgrade-pack");
                    }}
                  >
                    <img src={blueCircle} alt="medal-icon" />
                    {t("dashboardHeader.upgrade")}
                  </Link>
                </li>
              )}
              <li className="accountMenuItem">
                <Link to="/settings" onClick={() => setMySelfProfilView(false)}>
                  <img src={settings} alt="building-icon" />
                  {t("dashboardHeader.privacy")}
                </Link>
              </li>
              <li className="accountMenuItem" onClick={logOutUser}>
                <Link to="#">
                  <img src={logOut} alt="logout-icon" className="logOutIcon" />
                  {t("dashboardHeader.logOut")}
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default DashboardHeader;
