// Router
import {Link, useLocation, useNavigate} from "react-router-dom";

import {$api} from "../api/api";

// Images
import unArchive from "../assets/images/icons/un-archive.svg";
import threeDotIcon from "../assets/images/icons/threeDotIcon.svg";
import {ReactComponent as CircleCheckIcon} from "../assets/images/icons/tick-circle.svg";

// Translation
import {useTranslation} from "react-i18next";
import {DEFAULT_IMAGES, PROFILE_TYPES} from "../constants/constants";
import {useContext, useState} from "react";
import {LoginContext} from "../utils/Auth";

const ProfileCard = ({ data, inBox, remove, add }) => {
  const path = useLocation().pathname;
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isPro = data?.profile_type === PROFILE_TYPES.personal;
  const isBusiness = data?.profile_type === PROFILE_TYPES.business;
  const isBoxDetails = path?.includes("box-details");

  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  // Format statistic numbers
  const formatNumber = (number) => {
    const units = [
      t("numberFormats.k"),
      t("numberFormats.m"),
      t("numberFormats.b"),
      t("numberFormats.t"),
    ];
    const delimiter = ",";
    if (isNaN(number)) {
      return "";
    }
    if (number < 1000) {
      return number.toString();
    }
    const absNumber = Math.abs(number);
    let unitIndex = Math.floor(Math.log10(absNumber) / 3);
    let formattedNumber = (number / Math.pow(1000, unitIndex)).toFixed(1);
    if (formattedNumber.endsWith(".0")) {
      formattedNumber = formattedNumber.slice(0, -2);
    }
    if (unitIndex < units.length) {
      formattedNumber += " " + units[unitIndex - 1];
    } else {
      formattedNumber += " " + units[units.length - 1];
    }
    return formattedNumber.replace(".", delimiter);
  };

  const handleCardClick = (e) => {
    if (
      e.target.closest(".cardBtn") ||
      e.target.closest(".profileCardOverlay")
    ) {
      return;
    } else if (e.target.closest(".phone")) {
      return <a href={`tel:${data?.phone_number}`}></a>;
    } else {
      navigate(`/profile/${data?.username}`);
    }
  };

  return (
    <div className="profileCard" onClick={handleCardClick}>
      <div
        className={`profileCardOverlay ${isMenuOpen && "active"}`}
        onClick={() => setIsMenuOpen(false)}
      ></div>
      <div className="cardTop">
        <div className="images">
          <div
            className={`avatar ${isPro && "avatarPro"} ${
              data.avatar === null && "isDefault"
            }`}
          >
            {data.avatar ? (
              <img
                src={
                  path.includes("/search-result")
                    ? data.avatar
                    : `${
                        data?.profile?.avatar.includes("api.")
                          ? ""
                          : $api("image_url") + "/"
                      }${data.avatar}`
                }
                alt={data.full_name}
              />
            ) : (
              <img
                src={DEFAULT_IMAGES?.avatar_b64}
                alt="default-icon"
                className="defaultIcon"
              />
            )}
            {isPro && (
              <span
                className={`badge ${
                  data.avatar === null && "defaultBadgePosition"
                }`}
              >
                {t("profileCard.pro")}
              </span>
            )}
          </div>
          <div className="background">
            <img
              src={
                data?.background_image
                  ? path.includes("/search-result")
                    ? data.background_image
                    : `${
                        data?.profile?.background_image.includes("api.")
                          ? ""
                          : $api("image_url") + "/"
                      }${data.background_image}`
                  : DEFAULT_IMAGES.background_image_b64
              }
              alt="background_image"
            />
          </div>
        </div>
      </div>
      <div className="cardBottom">
        <div className="cardTitle">
          <div className="title">
            <p className="fullName">{data.full_name}</p>
            {isBusiness && (
              <span className="badge">{t("profileCard.company")}</span>
            )}
          </div>
          <span className="companyCount">
            {formatNumber(data?.in_boxes_count)} {t("profileCard.saved")}
          </span>
        </div>
        <div className="cardInfo">
          <div className="info">
            <p
              className="profession"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
              }}
            >
              {data?.bio}
            </p>
            <p className="profession">{data?.profession?.name}</p>
            {!isPro && !isBusiness && (
              <Link className="phone" to={`tel:${data?.phone_number}`}>
                {data?.phone_number}
              </Link>
            )}
          </div>
          {inBox && (
            <button
              className={`cardBtn ${!isBoxDetails && "remove"}`}
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen(!isMenuOpen);
              }}
              disabled={!isBoxDetails}
            >
              {isBoxDetails ? (
                <img src={threeDotIcon} alt="three-dot-icon" />
              ) : (
                <CircleCheckIcon />
              )}
              {isBoxDetails ? "" : t("profileCard.added")}
            </button>
          )}
          {!inBox && data?.id !== user?.profile?.id && (
            <button
              className="cardBtn add"
              onClick={(e) => {
                e.stopPropagation();
                add();
              }}
            >
              + {t("profileCard.add")}
            </button>
          )}
          {!inBox && data?.id === user?.profile?.id && (
            <button
              className="cardBtn"
              onClick={() => {
                navigate(`/profile/${user?.username}`);
              }}
            >
              {t("profileCard.view")}
            </button>
          )}
        </div>
      </div>
      <div className={`cardOption ${isMenuOpen && "isOpen"}`}>
        <div className="seperator"></div>
        <div
          className="option"
          onClick={() => {
            setIsMenuOpen(false);
            remove();
          }}
        >
          <img src={unArchive} alt="unArchive" />
          <span>{t("profileCard.delete")}</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
