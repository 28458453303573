import { $api } from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const postRegister = async (payload) => {
  return await $axios.post($api("user_register_email"), payload);
};

export const postGoogleRegister = async (payload) => {
  return await $axios.post($api("google"), payload);
};

export const getGoogleRegister = async () => {
  return await $axios.get($api("google"));
};

export const postLinkedinRegister = async (payload) => {
  return await $axios.post($api("linkedin"), payload);
};

export const getLinkedinRegister = async () => {
  return await $axios.get($api("linkedin"));
};

export const postRegisterOtp = async (payload) => {
  return await $axios.post($api("user_register_confirmation_v2"), payload);
};

export const postUserRefreshToken = async (payload) => {
  return await $axios.post($api("user_refresh_token"), payload);
};

export const postReActivateUser = async (payload) => {
  return await $axios.post($api("user_re_activate"), payload);
};

export const patchUserCreateUsername = async (payload) => {
  return await $axios.patch($api("user_update_single"), payload);
};

export const putActivateUser = async (payload) => {
  return await $axios.put($api("user_register_activate_user"), payload);
};

export const postResendOtp = async (payload) => {
  return await $axios.post($api("user_register_resend_otp_v2"), payload);
};

export const postResetPassword = async (payload) => {
  return await $axios.post($api("user_send_reset_password_otp_v2"), payload);
};

export const postSetNewPassword = async (payload) => {
  return await $axios.post($api("user_set_new_password_v2"), payload);
};

export const postConfirmResetPassword = async (payload) => {
  return await $axios.post($api("user_confirm_reset_password_v2"), payload);
};

export const putChangePassword = async (payload) => {
  return await $axios.put($api("user_change_password"), payload);
};

export const postUserLoginEmail = async (payload) => {
  return await $axios.post($api("user_login_v2"), payload);
};

export const postUserLoginPhone = async (payload) => {
  return await $axios.post($api("user_login"), payload);
};

export const getUserDetails = async () => {
  return await $axios.get($api("user_get"));
};

export const getUserProfileDetails = async () => {
  return await $axios.get($api("user_get_profile"));
};

export const putUpdateUsername = async (payload) => {
  return await $axios.put($api("user_update_single"), payload);
};
