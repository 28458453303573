import {useTranslation} from "react-i18next";

const UpgradePackCaption = () => {
  const { t } = useTranslation();

  return (
    <div className="upgradePackCaption">
      <p className="text">{t("upgradePackCaption.detail")}</p>
    </div>
  );
};

export default UpgradePackCaption;
