import {useContext} from "react";

// Images
import closeModal from "../assets/images/icons/close-modal.svg";

// Yup
import {object, string} from "yup";

// React hook form
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

// Translation
import {useTranslation} from "react-i18next";

import * as OrderBucardService from "../Modules/OrderBucard/Service/OrderBucardService";
import {LoginContext} from "../utils/Auth";
import {toastMsg} from "../utils/swal";

const OrderBucardModal = ({ cardId, isOpen, setIsOpen }) => {
  // Translation
  const { t } = useTranslation();

  const { user } = useContext(LoginContext);

  // Validate modal input
  const phoneRegex = /^\+(\d\s?){1,30}$/;
  const inputSchema = object({
    full_name: string().required(t("orderBucardModal.errorMsg1")),
    phone_number: string()
      .required(t("orderBucardModal.errorMsg2"))
      .matches(phoneRegex, t("orderBucardModal.rightPhoneFormat")),
    delivery_address: string().required(t("orderBucardModal.errorMsg3")),
    profession: string().required(t("orderBucardModal.errorMsg4")),
    order_code: string()
      .required(t("orderBucardModal.errorMsg5"))
      .matches(/^BUCARD2024$/, t("orderBucardModal.errorMsg6")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(inputSchema),
    values: {
      full_name: user?.full_name,
      phone_number: user?.phone_number,
      delivery_address: user?.profile?.address ? user?.profile?.address : "",
      profession: user?.profile?.profession?.name
        ? user?.profile?.profession?.name
        : "",
      order_code: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const res = await OrderBucardService.bucardOrderCard({
        ...data,
        card_style_id: cardId,
      });
      if (res.message) {
        toastMsg(t("orderBucardModal.orderSuccess"), "#115eed", "#ffffff");
      }
      reset();
      setIsOpen(false);
    } catch (err) {
      toastMsg(t("orderBucardModal.alreadyOrdered"), "red", "#ffffff");
    }
  };

  return (
    <div className={isOpen ? "orderBucardModal active" : "orderBucardModal"}>
      <div className="modalOverlay" onClick={() => setIsOpen(false)}></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="editModalHead">
          <h2 className="modalTitle">{t("orderBucardModal.title")}</h2>
          <div className="close" onClick={() => setIsOpen(false)}>
            <img src={closeModal} alt="close-icon" />
          </div>
        </div>
        <span className="infoText">{t("orderBucardModal.infoText")}</span>
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <input
            className="modalInput"
            type="text"
            name="full_name"
            placeholder={t("orderBucardModal.placeholder1")}
            {...register("full_name")}
          />
          {errors.full_name && (
            <span className="errorMessage">{errors.full_name.message}</span>
          )}
          <input
            className="modalInput"
            type="text"
            name="phone_number"
            placeholder={t("orderBucardModal.placeholder2")}
            {...register("phone_number")}
          />
          {errors.phone_number && (
            <span className="errorMessage">{errors.phone_number.message}</span>
          )}
          <input
            className="modalInput"
            type="text"
            name="profession"
            placeholder={t("orderBucardModal.placeholder4")}
            {...register("profession")}
          />
          {errors.profession && (
            <span className="errorMessage">{errors.profession.message}</span>
          )}
          <input
            className="modalInput"
            type="text"
            name="delivery_address"
            placeholder={t("orderBucardModal.placeholder3")}
            {...register("delivery_address")}
          />
          {errors.delivery_address && (
            <span className="errorMessage">
              {errors.delivery_address.message}
            </span>
          )}
          <input
            className="modalInput"
            type="text"
            name="order_code"
            placeholder={t("orderBucardModal.placeholder5")}
            {...register("order_code", {
              setValueAs: (value) => value.toUpperCase(),
            })}
            maxLength={10}
          />
          {errors.order_code && (
            <span className="errorMessage">{errors.order_code.message}</span>
          )}
          <button type="submit" className="addBox">
            {t("orderBucardModal.orderBtn")}
          </button>
        </form>
      </div>
      <div className="closeModal" onClick={() => setIsOpen(false)}>
        <img src={closeModal} alt="close-icon" />
        <span>{t("editBoxName.close")}</span>
      </div>
    </div>
  );
};

export default OrderBucardModal;
