import Marquee from "react-fast-marquee";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import appStore from "../../../assets/images/icons/app-store.svg";
import googlePlay from "../../../assets/images/icons/play-store.svg";
import homeProfileBadges from "../../../db/homeProfileBadges";
import particpants from "../../../db/particpants";
import {useContext, useState} from "react";
import {MainContext} from "../../../utils/MainContext";

const Hero = () => {
  const { t } = useTranslation();
  const { setStep } = useContext(MainContext);

  return (
    <section className="hero" id="hero">
      <div className="container">
        <div className="row">
          <div className="homeHero">
            <h1 className="heroTitle">{t("hero.title")}</h1>
            <p className="heroInfo">{t("hero.info")}</p>
            <div className="heroDescription">
              <p className="info">{t("hero.join")}</p>
              <div className="badgesContainer">
                {homeProfileBadges.map((item) => (
                  <div className="badge" key={item.id}>
                    <img src={item.image} alt={`profile badge ${item.id}`} />
                  </div>
                ))}
              </div>
              <p className="info">{t("hero.others")}</p>
            </div>
            <Link className="btn" to="/register" onClick={() => setStep(1)}>
              {t("hero.btnValue1")}
            </Link>
            <p className="mobileAppInfo">{t("hero.storeInfo")}</p>
            <div className="downloadApp">
              <div className="mobileApp">
                <Link
                  to="https://play.google.com/store/apps/details?id=bucard.tech&hl=az"
                  target="_blank"
                >
                  <img src={googlePlay} alt="download-google-play" />
                </Link>
              </div>
              <div className="mobileApp">
                <Link
                  to="https://apps.apple.com/us/app/bucard/id6478505117"
                  target="_blank"
                >
                  <img src={appStore} alt="download-app-store" />
                </Link>
              </div>
            </div>
          </div>
          <div className="particpants">
            <Marquee
              autoFill={true}
              speed={60}
              loop={0}
              gradient={true}
              gradientWidth={30}
            >
              {particpants.map((item) => (
                <div className="slide" key={item.id}>
                  <img src={item.image} alt={item.title} />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
