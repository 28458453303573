import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import eyeSlash from "../../../assets/images/icons/eye-slash.svg";
import eye from "../../../assets/images/icons/eye.svg";
import Button from "../../../components/Button";
import ErrorLabel from "../../../components/ErrorLabel";
import Loading from "../../../components/Loading";
import SuccessLabel from "../../../components/SuccessLabel";
import { LoginContext } from "../../../utils/Auth";
import { MainContext } from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";
import CustomInput from "../../../components/CustomInput";
import linkedinIcon from "../../../assets/images/icons/link-icon-white.svg";
import { GoogleLogin } from "@react-oauth/google";
import dayjs from "dayjs";

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    phone,
    setPhone,
    registerSuccessMsg,
    setRegisterSuccessMsg,
    setAllBoxes,
    setStep,
  } = useContext(MainContext);
  const { setIsUserIn } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneErrorVisible, setPhoneErrorVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState("email");

  const userSchema = object({
    email: string(),
    phone: string(),
    password: string()
      .required(t("login.errorMsg1"))
      .trim()
      .min(8, t("login.errorMsg2")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const handleChange = (value) => {
    setPhone(value);
    setValue(selectedTab === "email" ? "email" : "phone", value, {
      shouldValidate: true,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    let body = null;
    if (selectedTab === "email") {
      body = {
        email: data.email,
        password: data.password,
      };
    } else {
      body = {
        phone_number: data.phone,
        password: data.password,
      };
    }
    if (!isSubmitting) {
      setIsSubmitting(true);

      try {
        const res = await AuthService.userLogin(body, selectedTab);
        if (res.access) {
          setIsUserIn(true);
          setPhoneErrorVisible(false);
          navigate("/dashboard");
        }
      } catch (err) {
        if (err.response.data.detail) {
          if (err?.response?.data?.type.includes("inactive_user")) {
            const result = await AuthService.reActivateUser(body);
            console.log("reactivate user", result);
          } else {
            setErrorMsg(t("login.errorMsg6"));
          }
        }
      } finally {
        setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (registerSuccessMsg !== "") {
      const resetMsj = setTimeout(() => {
        setRegisterSuccessMsg("");
      }, 10000);

      return () => clearTimeout(resetMsj);
    }
  }, [registerSuccessMsg, setRegisterSuccessMsg]);

  const onSubmitLinkedin = async (e) => {
    e.preventDefault();
    const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${window.location.origin}/auth/linkedin&scope=openid%20profile%20email`;
    try {
      window.location.href = linkedInAuthURL;
    } catch (error) {
      console.log("Popup error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitGoogle = async (id) => {
    setLoading(true);
    try {
      const res = await AuthService.userGoogleRegister({
        id_token: id,
      });
      if (res?.access) {
        localStorage.setItem("X-Authorization", res.access);
        localStorage.setItem("X-Refresh", res.refresh);
        localStorage.setItem("tokenDate", JSON.stringify(dayjs()));
        localStorage.setItem("userIn", JSON.stringify(true));
      }
      setIsUserIn(true);
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">{t("login.title")}</h2>
      <p className="question">
        {t("login.noAccount")}{" "}
        <Link to="/register" onClick={() => setStep(1)}>
          {t("login.signUp")}
        </Link>
      </p>
      {registerSuccessMsg && <SuccessLabel text={registerSuccessMsg} />}
      <div className="socialBtnGroup">
        <GoogleLogin
          onSuccess={(res) => {
            onSubmitGoogle(res?.credential);
            console.log(res);
          }}
          onError={(err) => console.log("error", err)}
        />
        {/*<button className="btnWithLogo google" onClick={onSubmitGoogle}>*/}
        {/*  <div className="icon">*/}
        {/*    <img src={googleIcon} alt="google-icon" />*/}
        {/*  </div>*/}
        {/*  <span className="text">Google</span>*/}
        {/*</button>*/}
        <button className="btnWithLogo linkedin" onClick={onSubmitLinkedin}>
          <div className="icon">
            <img src={linkedinIcon} alt="linked-in-icon" />
          </div>
          <span className="text">LinkedIn</span>
        </button>
      </div>
      <div className="tabList">
        <p
          className={selectedTab === "email" ? "active" : ""}
          onClick={() => setSelectedTab("email")}
        >
          {t("login.email")}
        </p>
        <p
          className={selectedTab !== "email" ? "active" : ""}
          onClick={() => setSelectedTab("phone")}
        >
          {t("login.phone")}
        </p>
      </div>
      {selectedTab === "phone" && (
        <div className="phoneBox">
          <label htmlFor="phone">{t("login.phone")} *</label>
          <PhoneInput
            international
            defaultCountry="AZ"
            value={phone}
            onChange={handleChange}
            limitMaxLength={19}
          />
          {errors.phone && phoneErrorVisible && (
            <span className="errorAlert">{errors.phone.message}</span>
          )}
        </div>
      )}
      {selectedTab === "email" && (
        <CustomInput
          type={"email"}
          name={"email"}
          onChange={handleChange}
          label={t("registerStepOne.email")}
          placeholder={t("registerStepOne.emailPlace")}
        />
      )}
      <div className="inputBox">
        <label htmlFor="password">{t("login.pass")} *</label>
        <input
          type={showPassword ? "text" : "password"}
          className={errors.password ? "formInput err" : "formInput"}
          id="password"
          name="password"
          placeholder="********"
          {...register("password")}
        />
        {errors.password && (
          <span className="errorAlert">{errors.password.message}</span>
        )}
        <div
          className="eye"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img
            src={showPassword ? eyeSlash : eye}
            alt={showPassword ? "Hide password" : "Show password"}
          />
        </div>
        <div className="forgot">
          <Link to="/forgot-password">{t("login.forgotPass")}</Link>
        </div>
      </div>
      {errorMsg && <ErrorLabel text={errorMsg} />}
      <Button
        className="formBtn"
        disabled={errors.password && true}
        onClick={() => setPhoneErrorVisible(true)}
      >
        {t("login.btnValue")}
      </Button>
    </form>
  );
};

export default LoginForm;
