import React, {useState} from "react";
import * as ProfileService from "../../../../Profile/Service/ProfileService";
import CustomInput from "../../../../../components/CustomInput";
import Spinner from "../../../../../components/Spinner";
import Swal from "sweetalert2";
import {toastMsg} from "../../../../../utils/swal";
import {useTranslation} from "react-i18next";

const EmailEditModal = ({ closeModal, setProfileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newEmail, setNewEmail] = useState({
    email: "",
    isVerified: false,
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      let res = null;
      res = await ProfileService.profileEdit({
        extra_email: [{ email: newEmail.email }],
      });
      setProfileData((prev) => ({
        ...prev,
        extra_emails: res?.extra_emails,
      }));
      Swal.fire(
        t("emailEditModal.success"),
        t("emailEditModal.successNotify"),
        "success"
      );
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error?.response?.data?.extra_email?.includes("exists")) {
        toastMsg(t("emailEditModal.alreadyAdded"), "#ffa700", "#000000");
        setNewEmail({
          email: "",
          isVerified: false,
        });
      }
      if (error?.response?.data?.extra_email[0]?.email?.length) {
        setErrorMessage(t("profileEmailEdit.invalidEmailFormat"));
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="emailEditModal">
      <form onSubmit={(e) => e.preventDefault()} className="infoEmailForm">
        <CustomInput
          label={t("emailEditModal.newEmail")}
          placeholder="info@bucard.az"
          errorMessage={errorMessage}
          value={newEmail.email}
          onChange={(value) => {
            const email = value.trim();
            setNewEmail({ email });
            if (email === "") {
              setErrorMessage(t("emailEditModal.enterEmail"));
            } else {
              setErrorMessage("");
            }
          }}
        />
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          {t("emailEditModal.cancel")}
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          disabled={errorMessage !== ""}
        >
          {t("emailEditModal.save")}
        </button>
      </div>
    </div>
  );
};

export default EmailEditModal;
