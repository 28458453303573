import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import eyeSlash from "../../../assets/images/icons/eye-slash.svg";
import eye from "../../../assets/images/icons/eye.svg";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import { MainContext } from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const RecoverForm = ({ data }) => {
  const { t } = useTranslation();
  // Route
  const { phone, key } = data;
  const { setRegisterSuccessMsg } = useContext(MainContext);

  // Local state
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);

  //   Router
  const navigate = useNavigate();

  useEffect(() => {
    if (redirecting) {
      if (count > 0) {
        const timer = setTimeout(() => {
          setCount(count - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setRegisterSuccessMsg(t("recoverForm.changed"));
        navigate("/login");
      }
    }
  }, [count, redirecting, navigate]);

  // Yup schema
  const userSchema = object({
    password: string()
      .required(t("login.errorMsg1"))
      .trim()
      .min(8, t("login.errorMsg2")),
    confirmPassword: string()
      .required(t("recoverForm.tryAgain"))
      .test("passwords-match", t("recoverForm.dontMatch"), function (value) {
        return this.parent.password === value;
      }),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  console.log("errors", errors);
  console.log('bu sehife');
  // Form on submit
  const onSubmit = async (data) => {
    console.log("submit 1");
    setErrorMessage("");
    setLoading(true);
    const body = {
      email: phone,
      session_key: key,
      new_password: data.password,
    };

    try {
      console.log("submit 2");

      await AuthService.setNewPassword(body);
      setRedirecting(true);
    } catch (err) {
      console.log("submit 3");

      console.log(err);
      if (err.response && err.response.data && err.response.data.message) {
        errors.password = "";
        setErrorMessage(t("errors.systemError"));
      }
    } finally {
      console.log("submit 4");

      setLoading(false);
    }
  };

  return (
    <form className="recoverForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      {redirecting && (
        <div className="redirect">
          <h3 className="infoText">{t("recoverForm.finish")}</h3>
          <p>
            <span className="timer">{count}</span> {t("recoverForm.redirect")}
          </p>
          <p>
            {t("recoverForm.now")}
            <Link to="/login">{t("recoverForm.login")}</Link>
          </p>
        </div>
      )}
      <h2 className="formTitle">{t("forgotPassword.btnValue")}</h2>
      <p className="question">{t("forgotPassword.createPassword")}</p>
      <div className="inputBox">
        <label htmlFor="password">{t("forgotPassword.newPassword")} *</label>
        <input
          type={showPassword ? "text" : "password"}
          className={errors.password ? "formInput err" : "formInput"}
          id="password"
          name="password"
          placeholder="********"
          {...register("password")}
        />
        {errors.password && (
          <span className="errorAlert">{errors.password.message}</span>
        )}
        <div
          className="eye"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img
            src={showPassword ? eyeSlash : eye}
            alt={showPassword ? "Hide password" : "Show password"}
          />
        </div>
      </div>
      <div className="inputBox">
        <label htmlFor="confirmPassword">
          {t("forgotPassword.repeatPassword")} *
        </label>
        <input
          type={showPassword ? "text" : "password"}
          className={errors.confirmPassword ? "formInput err" : "formInput"}
          id="confirmPassword"
          name="confirmPassword"
          placeholder="********"
          {...register("confirmPassword")}
        />
        {errors.confirmPassword && (
          <span className="errorAlert">{errors.confirmPassword.message}</span>
        )}
        <div
          className="eye"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img
            src={showPassword ? eyeSlash : eye}
            alt={showPassword ? "Hide password" : "Show password"}
          />
        </div>
      </div>
      {errorMessage && <span className="errorAlert">{errorMessage}</span>}
      <Button
        className="formBtn"
        disabled={errors.password && errors.confirmPassword}
      >
        {t("registerStepOne.btnValue")}
      </Button>
    </form>
  );
};

export default RecoverForm;
