import dayjs from "dayjs";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../../utils/Auth";
import * as AuthProvider from "../Provider/AuthProvider";

export const userRegister = async (payload) => {
  return await AuthProvider.postRegister(payload).then((res) => {
    return res.data;
  });
};

export const userGoogleRegister = async (payload) => {
  return await AuthProvider.postGoogleRegister(payload).then((res) => {
    localStorage.setItem("X-Authorization", res.data.access);
    localStorage.setItem("X-Refresh", res.data.refresh);
    localStorage.setItem("tokenDate", JSON.stringify(dayjs()));
    return res.data;
  });
};

export const userGoogleRegisterData = async () => {
  return await AuthProvider.getGoogleRegister().then((res) => {
    return res.data;
  });
};

export const userLinkedinRegister = async (payload) => {
  return await AuthProvider.postLinkedinRegister(payload).then((res) => {
    return res.data;
  });
};

export const userLinkedinRegisterData = async () => {
  return await AuthProvider.getLinkedinRegister().then((res) => {
    return res.data;
  });
};

export const userRefreshToken = async (payload) => {
  return await AuthProvider.postUserRefreshToken(payload).then((res) => {
    return res.data;
  });
};

export const reActivateUser = async (payload) => {
  return await AuthProvider.postReActivateUser(payload).then((res) => {
    return res.data;
  });
};

export const confirmOtp = async (payload) => {
  return await AuthProvider.postRegisterOtp(payload).then((res) => {
    localStorage.setItem("X-Authorization", res.data.access);
    localStorage.setItem("X-Refresh", res.data.refresh);
    localStorage.setItem("tokenDate", JSON.stringify(dayjs()));
    return res.data;
  });
};

export const activateUser = async (payload) => {
  return await AuthProvider.putActivateUser(payload).then((res) => {
    localStorage.setItem("userIn", JSON.stringify(true));
    return res.data;
  });
};

export const createUsername = async (payload) => {
  return await AuthProvider.patchUserCreateUsername(payload).then((res) => {
    return res.data;
  });
};

export const resendOtp = async (payload) => {
  return await AuthProvider.postResendOtp(payload).then((res) => {
    return res.data;
  });
};

export const resetPassword = async (payload) => {
  return await AuthProvider.postResetPassword(payload).then((res) => {
    return res.data;
  });
};

export const confirmResetPassword = async (payload) => {
  return await AuthProvider.postConfirmResetPassword(payload).then((res) => {
    return res.data;
  });
};

export const setNewPassword = async (payload) => {
  return await AuthProvider.postSetNewPassword(payload).then((res) => {
    return res.data;
  });
};

export const changePassword = async (payload) => {
  return await AuthProvider.putChangePassword(payload).then((res) => {
    return res.data;
  });
};

const setCredentials = async (res) => {
  localStorage.setItem("X-Authorization", res.data.access);
  localStorage.setItem("X-Refresh", res.data.refresh);
  localStorage.setItem("tokenDate", JSON.stringify(dayjs()));
  localStorage.setItem("userIn", JSON.stringify(true));
};

export const userLogin = async (payload, type) => {
  if (type === "phone") {
    return await AuthProvider.postUserLoginPhone(payload).then((res) => {
      setCredentials(res);
      return res.data;
    });
  } else {
    return await AuthProvider.postUserLoginEmail(payload).then((res) => {
      setCredentials(res);
      return res.data;
    });
  }
};

export const userLoggedIn = async (payload) => {};

export const useUserLogOut = () => {
  const navigate = useNavigate();
  const { setIsUserIn, setUser } = useContext(LoginContext);

  const handleLogOut = () => {
    localStorage.removeItem("userIn");
    localStorage.removeItem("X-Authorization");
    localStorage.removeItem("X-Refresh");
    localStorage.removeItem("tokenDate");
    setIsUserIn(false);
    setUser(null);
    navigate("/");
  };

  return handleLogOut;
};

export const getUser = async () => {
  return await AuthProvider.getUserDetails().then((res) => {
    return res.data;
  });
};

export const getProfile = async () => {
  return await AuthProvider.getUserProfileDetails().then((res) => {
    return res.data;
  });
};

export const updateUsername = async (payload) => {
  return await AuthProvider.putUpdateUsername(payload).then((res) => {
    return res.data;
  });
};
