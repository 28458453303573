import {useContext, useEffect, useState} from "react";

// Router
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

// Images
import boxes from "../assets/images/icons/boxes-black.svg";
import close from "../assets/images/icons/close-modal.svg";
import BDIcon from "../assets/images/bd-logo.svg";
import portfolio from "../assets/images/icons/portfolio.svg";
import userImg from "../assets/images/icons/user-img.svg";
import logo from "../assets/images/logo.svg";
import bucardCard from "../assets/images/icons/bucard-card.svg";
import searchIcon from "../assets/images/icons/search-black.svg";
import orderCard from "../assets/images/icons/order-card.svg";
import company from "../assets/images/icons/company.svg";
import noUserImage from "../assets/images/icons/no-user-image.svg";
import logOut from "../assets/images/icons/logout.svg";
import settings from "../assets/images/icons/setting-2.svg";

// Components
import LanguageSelector from "./LanguageSelector";
import MobileLanguageSelect from "./MobileLanguageSelect";

// Context
import {LoginContext} from "../utils/Auth";
import {MainContext} from "../utils/MainContext";

// Translation
import {useTranslation} from "react-i18next";

import {useUserLogOut} from "../Modules/Auth/Service/AuthService";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";
import {$api} from "../api/api";

const SideBar = () => {
  const navigate = useNavigate();
  // Translation
  const { t } = useTranslation();

  // Locaction For Current Page Check
  const location = useLocation();

  // Params
  const { id } = useParams();

  // Global states
  const { user } = useContext(LoginContext);
  const {
    setDashboardPage,
    dashboardPage,
    sideBarOpen,
    setSideBarOpen,
    hasCardOrdered,
    setMySelfProfilView,
  } = useContext(MainContext);

  // Local State Sidebar Visible For Profile Page
  const [sideBarVisible, setSideBaVisible] = useState(false);

  // Mobile Langusage Modal Visible
  const [mobileLang, setMobileLang] = useState(false);

  const userLogOut = useUserLogOut();

  const logOutUser = () => {
    setSideBarOpen(false);
    userLogOut();
    setMySelfProfilView(false);
  };

  // SideBar Menu Visible Set
  useEffect(() => {
    if (location.pathname.includes("/profile")) {
      setSideBaVisible(true);
    } else {
      setSideBaVisible(false);
    }
  }, [location, id]);

  //  Screen Width  Check for Mobile Lang Modal
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMobileLang(false);
      } else {
        setMobileLang(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <aside
      className={
        sideBarOpen
          ? "sideBar isOpen"
          : dashboardPage === "profile"
          ? "sideBar profilePage"
          : dashboardPage === "profile" && sideBarOpen
          ? "sideBar profilePage isOpen"
          : "sideBar"
      }
    >
      <div className="leftSide">
        <div className="leftTop">
          <div
            className={`badge ${
              location.pathname === "/dashboard" && "active"
            }`}
          >
            <Link
              to={`/dashboard`}
              onClick={() => {
                setDashboardPage("boxes");
                setMySelfProfilView(false);
              }}
            >
              <img src={BDIcon} alt="logo-icon" />
            </Link>
          </div>
          <div
            className={`badge ${
              location.pathname.includes("profile") && "active"
            }`}
          >
            <Link to={`/profile/${user?.profile?.username}`}>
              <img
                className="profileImg"
                src={
                  user?.profile?.avatar &&
                  user?.profile?.profile_type !== PROFILE_TYPES.free
                    ? `${
                        !user?.profile?.avatar.includes("api.")
                          ? $api("image_url") + "/"
                          : ""
                      }${user?.profile?.avatar}`
                    : userImg
                }
                alt="logo-icon"
              />
            </Link>
          </div>
          <span className="divider"></span>
        </div>
      </div>
      {!sideBarVisible && (
        <div className="rightSide">
          <ul className="menuList">
            <li
              className={`menuListItem ${
                dashboardPage === "boxes" ? "active" : ""
              }`}
              onClick={() => {
                setDashboardPage("boxes");
                navigate("/dashboard");
                setMySelfProfilView(false);
              }}
            >
              <img src={boxes} alt="boxes-icon" />
              <p className="itemTitle">{t("sideBar.boxes")}</p>
            </li>
            <li
              className="menuListItem "
              onClick={() => {
                navigate("/search");
                setMySelfProfilView(false);
              }}
            >
              <img src={searchIcon} alt="boxes-icon" />
              <p className="itemTitle">{t("sideBar.search")}</p>
            </li>
            {!hasCardOrdered && (
              <li
                className={`menuListItem ${
                  dashboardPage === "order-bucard" ? "active" : ""
                }`}
                onClick={() => {
                  setDashboardPage("order-bucard");
                  navigate("/order-bucard");
                  setMySelfProfilView(false);
                }}
              >
                <img className="" src={orderCard} alt="orderCard" />
                <p className="itemTitle ">{t("sideBar.order")}</p>
              </li>
            )}
            <li
              className={`menuListItem ${
                dashboardPage === "companies" ? "active" : ""
              }`}
            >
              <img className="soon" src={company} alt="company" />
              <p className="itemTitle soon">{t("sideBar.companies")}</p>
            </li>
          </ul>
          <div className="upgradeBox">
            {user?.profile?.profile_type !== PROFILE_TYPES.personal && (
              <button
                className="upgradeBtn"
                onClick={() => {
                  setDashboardPage("upgrade-pack");
                  navigate("/dashboard");
                  setSideBarOpen(false);
                  setMySelfProfilView(false);
                }}
              >
                <p>{t("sideBar.upgrade")}</p>
              </button>
            )}
          </div>
        </div>
      )}
      <div className="mobileSide">
        <div className="mobileHead">
          <div className="logo" onClick={() => setSideBarOpen(false)}>
            <Link to="/dashboard">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="btns">
            <LanguageSelector />
            <div className="close" onClick={() => setSideBarOpen(false)}>
              <img src={close} alt="close-icon" />
            </div>
          </div>
        </div>
        <div className="mobileBody">
          <ul className="menu">
            <li className="accountMenuItem">
              <Link
                to={`/profile/${user?.username}`}
                onClick={() => {
                  setSideBarOpen(false);
                  setMySelfProfilView(false);
                }}
              >
                <img
                  src={
                    user?.profile?.avatar ? user?.profile?.avatar : noUserImage
                  }
                  alt="medal-icon"
                />
                {user?.full_name}
              </Link>
            </li>
            <li className="menuItem" onClick={() => setSideBarOpen(false)}>
              <div className="icon">
                <img src={portfolio} alt="portfolio-icon" />
              </div>
              <p className="itemName">
                {t("sideBar.companies")} - {t("sideBar.soon")}
              </p>
            </li>
            <li className="accountMenuItem">
              <Link to="/settings" onClick={() => setMySelfProfilView(false)}>
                <img src={settings} alt="building-icon" />
                {t("dashboardHeader.privacy")}
              </Link>
            </li>
            <div className="upgradeBox">
              {!hasCardOrdered && (
                <button
                  onClick={() => {
                    setSideBarOpen(false);
                    navigate("/order-bucard");
                  }}
                  className="orderBucard"
                >
                  <div className="cardImg">
                    <img src={bucardCard} alt="bucard-card" />
                  </div>
                  <p className="orderInfo">{t("sideBar.order")}</p>
                </button>
              )}
              {user?.profile?.profile_type !== PROFILE_TYPES.personal && (
                <button
                  className="upgradeBtn"
                  onClick={() => {
                    setDashboardPage("upgrade-pack");
                    navigate("/dashboard");
                    setSideBarOpen(false);
                    setMySelfProfilView(false);
                  }}
                >
                  <div className="upgradeIcon"></div>
                  <p>{t("sideBar.upgrade")}</p>
                </button>
              )}
            </div>
            <li className="accountMenuItem" onClick={logOutUser}>
              <Link to="#">
                <img src={logOut} alt="logout-icon" />
                {t("dashboardHeader.logOut")}
              </Link>
            </li>
          </ul>
          {mobileLang && <MobileLanguageSelect />}
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
