import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import blueCircle from "../../../../assets/images/icons/blue-circle.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import logOut from "../../../../assets/images/icons/logout.svg";
import MailIcon from "../../../../assets/images/icons/mail.svg";
import noUserImage from "../../../../assets/images/icons/no-user-image.svg";
import PhoneIcon from "../../../../assets/images/icons/phone.svg";
import settingsIcon from "../../../../assets/images/icons/setting-2.svg";
import {DEFAULT_IMAGES, PROFILE_TYPES} from "../../../../constants/constants";
import {LoginContext} from "../../../../utils/Auth";
import {MainContext} from "../../../../utils/MainContext";
import {useUserLogOut} from "../../../Auth/Service/AuthService";
import ContactItem from "../components/ContactItem";
import ProfileInfo from "../components/ProfileInfo";
import {handleCopyToClipboard} from "../../../../utils/helper";

const ProfileHero = ({
  profileData,
  onEditProfilePhoto,
  onEditProfileInfo,
  onEditPhoneNumbers,
  onEditEmails,
  showProfileInfoEdit,
  showPhoneNumbersEdit,
  showEmailsEdit,
  onEditBackgroundPhoto,
  isOwner,
  isProUser,
}) => {
  const { t } = useTranslation();

  // Custom hook
  const userLogOut = useUserLogOut();

  // Global states
  const { user } = useContext(LoginContext);
  const { setDashboardPage, setMySelfProfilView } = useContext(MainContext);
  const [openMenu, setOpenMenu] = useState(false);

  const logOutUser = () => {
    setDashboardPage("boxes");
    userLogOut();
    setMySelfProfilView(false);
  };

  return (
    <section className="profileHero">
      <div className="row">
        <div
          className={`overlay ${openMenu ? "active" : ""}`}
          onClick={() => setOpenMenu(false)}
        ></div>
        <div className="topSide">
          <div className="mobileTopBar">
            <p
              className="username"
              onClick={() =>
                handleCopyToClipboard(
                  profileData?.username,
                  t("messages.copy"),
                  t("errors.errorOnCopy")
                )
              }
            >
              {/* @{profileData?.username} */}
            </p>
            {isOwner && (
              <img
                src={settingsIcon}
                alt="settings_icon"
                onClick={() => setOpenMenu(!openMenu)}
              />
            )}
            <div className="account">
              <ul className={openMenu ? "accountMenu open" : "accountMenu"}>
                <li className="accountMenuItem">
                  <Link
                    to={
                      user?.profile?.profile_type !== PROFILE_TYPES.free &&
                      `/profile/${user?.username}`
                    }
                    onClick={() => {
                      setMySelfProfilView(false);
                      setOpenMenu(false);
                    }}
                  >
                    <img
                      src={
                        user?.profile?.avatar
                          ? user?.profile?.avatar
                          : noUserImage
                      }
                      alt="medal-icon"
                    />
                    {user?.full_name}
                  </Link>
                </li>
                {user?.profile?.profile_type === PROFILE_TYPES.free && (
                  <li className="accountMenuItem">
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        setMySelfProfilView(false);
                        setOpenMenu(false);
                        setDashboardPage("upgrade-pack");
                      }}
                    >
                      <img src={blueCircle} alt="medal-icon" />
                      {t("dashboardHeader.upgrade")}
                    </Link>
                  </li>
                )}
                <li className="accountMenuItem">
                  <Link
                    to="/settings"
                    onClick={() => {
                      setOpenMenu(false);
                      setMySelfProfilView(false);
                    }}
                  >
                    <img src={settingsIcon} alt="settings-icon" />
                    {t("dashboardHeader.privacy")}
                  </Link>
                </li>
                <li className="accountMenuItem" onClick={logOutUser}>
                  <Link to="#">
                    <img src={logOut} alt="logout-icon" />
                    {t("dashboardHeader.logOut")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="images">
            <div
              className={`avatar ${
                profileData?.profile_type === PROFILE_TYPES.personal &&
                "avatarPro"
              }`}
            >
              <img
                src={profileData.avatar || DEFAULT_IMAGES.avatar_b64}
                alt={profileData.full_name}
              />
              {profileData?.profile_type === PROFILE_TYPES.personal && (
                <span className="badge">Pro</span>
              )}
              {isOwner && (
                <div className="editIcon" onClick={() => onEditProfilePhoto()}>
                  <img src={PenIcon} alt="pen-icon" />
                </div>
              )}
            </div>

            <div className="background">
              <img
                src={
                  profileData.background_image ||
                  DEFAULT_IMAGES?.background_image_b64
                }
                alt="background_image"
              />
              {isOwner && isProUser && (
                <div
                  className="editIcon"
                  onClick={() => onEditBackgroundPhoto()}
                >
                  <img src={PenIcon} alt="pen-icon" />
                </div>
              )}
            </div>
          </div>
          <ProfileInfo
            isProUser={isProUser}
            isOwner={isOwner}
            profileData={profileData}
            onEditProfileInfo={() => onEditProfileInfo()}
            showProfileInfoEdit={showProfileInfoEdit}
          />
          {/*<CompleteProfile />*/}
        </div>
        <div className="bottomSide">
          <div className="profileContact">
            <h4 className="title">{t("profileHero.contactInfo")}</h4>
            <ContactItem
              isOwner={isOwner}
              icon={PhoneIcon}
              mainContact={profileData.phone_number}
              data={profileData.extra_phone_numbers}
              title={t("profileHero.phone")}
              type={"tel"}
              onEdit={() => onEditPhoneNumbers()}
              showContactEdit={showPhoneNumbersEdit}
            />
            {isProUser &&
            (isOwner ||
              profileData?.email ||
              profileData?.extra_emails?.some((item) => item.is_verified)) ? (
              <ContactItem
                isOwner={isOwner}
                icon={MailIcon}
                mainContact={profileData.email}
                data={profileData.extra_emails}
                title={t("profileHero.email")}
                type={"mailto"}
                onEdit={() => onEditEmails()}
                showContactEdit={showEmailsEdit}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileHero;
