import React, {useContext, useEffect, useState} from "react";
import UpgradePackCaption from "../components/UpgradePackCaption";
import UpgradePackPacketArea from "../components/UpgradePackPacketArea";
import PacketCard from "../components/PacketCard";
import Loading from "./Loading";
import * as HomeService from "../Modules/Home/Service/HomeService";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";
import {LoginContext} from "../utils/Auth";
import BuyPackModal from "./BuyPackModal";

const UpgradeFreePack = () => {
  const { user } = useContext(LoginContext);
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      try {
        const res = await HomeService.planAllList();
        setPlanList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  return (
    <div className="upgradeFreePack">
      {loading && <Loading />}
      <div className="packetArea">
        <UpgradePackCaption />
        <div className="row">
          {planList
            .filter((plan) => plan.profile_type !== "business")
            .map((item) => {
              if (item.profile_type === PROFILE_TYPES.personal) {
                return (
                  <PacketCard
                    id={item.id}
                    data={item}
                    key={item.id}
                    profile_type={user?.profile?.profile_type}
                  />
                );
              } else {
                return;
              }
            })}
        </div>
      </div>
      <UpgradePackPacketArea />
      <BuyPackModal userId={user?.profile?.id} />
    </div>
  );
};

export default UpgradeFreePack;
