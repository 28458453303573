import React, {useState} from "react";
import bgImg from "../../../../assets/images/profile-bg.png";
import InfoCircle from "../../../../assets/images/icons/info-circle.svg";
import Bag from "../../../../assets/images/icons/bag-blue.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import {DEFAULT_IMAGES} from "../../../../constants/constants";
import Spinner from "../../../../components/Spinner";
import * as ProfileService from "../../Service/ProfileService";
import {useTranslation} from "react-i18next";

const ProfileBackgroundImage = ({ closeModal, profileData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState({
    profile: {
      background_image_b64: "",
    },
  });
  const [error, setError] = useState("");

  const selectImage = async (e, isLocal) => {
    try {
      const file = e.target.files[0];
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        setError(t("profileBgEdit.invalidFileType"));
        return;
      } else {
        setError("");
      }
      if (isLocal) {
        const base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            background_image_b64: base64,
          },
        }));
      } else {
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            background_image_b64: DEFAULT_IMAGES.background_image_b64,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeImage = async () => {
    setLoading(true);
    try {
      await ProfileService.userEdit(preview);
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="editBackgroundPhoto">
      <div className="editBackgroundComp">
        <div className="infoArea">
          <img src={InfoCircle} alt="circle-icon" />
          <p>{t("profileBgEdit.bgSize").replace("size", "481x120")}</p>
        </div>
        <div className="backgroundImage">
          <img
            src={
              preview.profile.background_image_b64 ||
              profileData.background_image ||
              bgImg
            }
            alt={profileData.full_name}
          />
        </div>
        {error && <p className="errorMsg">{error}</p>}
        <span></span>
        <div className="btnGroup">
          <div className="editBtn">
            {!preview.profile.background_image_b64 && (
              <button
                className="removeBtn"
                onClick={async () => {
                  selectImage(null, false);
                  if (preview.profile.background_image_b64) {
                    await changeImage();
                  }
                }}
              >
                <img src={Bag} alt="remove_icon" />
                {t("profileBgEdit.delete")}
              </button>
            )}
            <div className="saveBtn">
              <label
                htmlFor="imgSelect"
                onClick={async () => {
                  if (preview.profile.background_image_b64) {
                    await changeImage();
                  }
                }}
              >
                {!preview.profile.background_image_b64 && (
                  <img src={PenIcon} alt="pen_icon" />
                )}{" "}
                {preview.profile.background_image_b64
                  ? t("profileBgEdit.complete")
                  : t("profileBgEdit.change")}
              </label>
              <input
                id="imgSelect"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => selectImage(e, true)}
              />
            </div>
          </div>
          {!preview.profile.background_image_b64 && (
            <div
              className="cancelBtn"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              <button className="btnCancel">{t("profileBgEdit.cancel")}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileBackgroundImage;
